import React, {FunctionComponent} from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";

export type SEOProps = {
    description?:string, lang?:string, meta?:any[], keywords?:string[], title:string, image?:any, bodyAttr?:string
};

const SEO: FunctionComponent<SEOProps> = props => {

  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription = props.description || (data.site && data.site.siteMetadata && data.site.siteMetadata.description) || "";
        const siteTitle = (data.site && data.site.siteMetadata.title) || "";
        const siteAuthor = (data.site && data.site.siteMetadata.author && data.site.siteMetadata.author.name) || "";
        const metaImage =
            props.image && props.image.asset
            ? imageUrlFor(buildImageObj(props.image))
                .width(1200)
                .url()
            : "";

        const pageTitle = props.title || siteTitle;

        return (
          <Helmet
            bodyAttributes={props.bodyAttr}
            htmlAttributes={{ lang: props.lang }}
            title={pageTitle}
            titleTemplate={pageTitle === siteTitle ? siteTitle : `%s | ${siteTitle}`}
            meta={[
              {
                name: "google-site-verification",
                content: "7MfJFsxBVui5UlEBExUFeMW9-Q6g9fPgoaxwzvbqaV0"
              },
              {
                name: "description",
                content: metaDescription
              },
              {
                property: "og:title",
                content: props.title
              },
              {
                property: "og:description",
                content: metaDescription
              },
              {
                property: "og:type",
                content: "website"
              },
              {
                property: "og:image",
                content: metaImage
              },
              {
                name: "twitter:card",
                content: "summary"
              },
              {
                name: "twitter:creator",
                content: siteAuthor
              },
              {
                name: "twitter:title",
                content: props.title
              },
              {
                name: "twitter:description",
                content: metaDescription
              }
            ]
              .concat(
                  props.keywords && props.keywords.length > 0
                  ? {
                      name: "keywords",
                      content: props.keywords.join(", ")
                    }
                  : []
              )
              .concat(props.meta)}
          >
          </Helmet>
        );
      }}
    />
  );
}

SEO.defaultProps = {
  lang: "en",
  meta: [],
  keywords: []
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
   site {
      siteMetadata {
        title
        description
        author {
            name
        }
      }
    }
  }
`;
