import React, { FunctionComponent, useState } from 'react';

import axios from "axios";

export type FormProps = {};

const WebhookForm: FunctionComponent<FormProps> = () => {
    const [formStatus, setFormStatus] = useState(false);
    const [query, setQuery] = useState({
        name: "",
        email: "",
        message: "",
        phone: ""
    });
    const handleInputChange = () => e => {
        const name = e.target.name;
        const value = e.target.value;
        setQuery(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = e => {
        e.preventDefault();
        const formData = new FormData();
        Object.entries(query).forEach(([key, value]) => {
            formData.append(key, value);
        });

        axios
            .post("https://webhooks.integrately.com/a/webhooks/04abca5c5f4f4913a1e4501641dabed9", formData, {
                headers: { Accept: "application/json" }
            })
            .then(function(response) {
                setFormStatus(true);
                setQuery({
                    name: "",
                    email: "",
                    message: "",
                    phone: ""
                });
                console.log(response);
            })
            .catch(function(error) {
                console.log(error);
            });
    };

    return (
        <>
            <h3>Contact me<br/><br/>for more info</h3>
            <form className="contactMe small" onSubmit={handleSubmit}>
                <section>
                    <div className="form-row">
                        <div className="title">Your name</div>
                        <input onChange={handleInputChange()} type="text" name="name" data-displayname="Name" className="field"
                               placeholder="Your name" required value={query.name}/>
                    </div>
                    <div className="form-row">
                        <div className="title">Your e-mail</div>
                        <input onChange={handleInputChange()} type="email" name="email" data-displayname="Email" className="field"
                               placeholder="Your e-mail" required value={query.email}/>
                    </div>
                    <div className="form-row">
                        <div className="title">Your phone number</div>
                        <input onChange={handleInputChange()} type="tel" name="phone" data-displayname="Phone" className="field"
                               placeholder="Your phone number" required value={query.phone}/>
                    </div>
                    <div className="form-row">
                        <div className="title">Message</div>
                        <textarea onChange={handleInputChange()} name="message" data-displayname="Message" className="field"
                                  placeholder="Message" required value={query.message}/>
                    </div>
                    {formStatus ? (
                        <>
                            <div className="msg">Your message has been sent.</div>
                            <br />
                        </>
                    ) : (
                        ""
                    )}
                    <button className="btn" type="submit" data-sending="Sending...">Send</button>
                </section>
            </form>
        </>
    );
}

export default WebhookForm;


