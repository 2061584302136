import React from "react"

import { StaticImage as Img } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import WebhookForm from "../components/WebhookForm";

const Home = () => {

    return (

        <Layout>
            <SEO title={'Pseekoo'}/>
            <section className="pseekoo">
                <div className="infos">
                    <Img
                        placeholder="blurred"
                        src="../images/running.jpg"
                        alt={"Cover"}
                        className={"cover-image"}
                        imgClassName={"cover-image"}
                        objectFit={"cover"}
                        formats={["auto", "webp"]}
                        quality={100}
                    />
                    <div className="contain">
                        <div>
                            <div>
                                <Img
                                    placeholder="blurred"
                                    src="../images/pseekoo-transparent.png"
                                    alt={"Logo"}
                                    className={"logo"}
                                    imgClassName={"logo"}
                                    objectFit={"cover"}
                                    formats={["auto", "webp"]}
                                    quality={100}
                                />
                                <h1 className="title">FREELANCE</h1>
                                <div className="description">
                                    {/*<sheet-list-view/>*/}
                                </div>
                                <a href="https://www.google.it/maps/place/Polling+in+Tirol+92,+6404+Polling+in+Tirol/@47.280065,11.1501467,17z/data=!3m1!4b1!4m5!3m4!1s0x479d16dfeb83b61d:0xf36797c774f7f82c!8m2!3d47.2800614!4d11.1523407"
                                   target="_blank" className="address">6404 Polling in Tirol 92<br/>Austria</a>
                                <div className="social">
                                    <a href="https://www.facebook.com/Pseekoo-1542677229367789/"
    className="facebook" target="_blank"/>
                                    <a href="https://wa.me/+43 5238 22203" className="whatsapp"
    target="_blank"/>
                                </div>
                                {/*<p>&nbsp;</p>*/}
                                {/*<p>&nbsp;</p>*/}
                                {/*<div>*/}
                                {/*    <a href="https://www.iubenda.com/privacy-policy/679662"*/}
                                {/*       className="iubenda-white no-brand iubenda-embed" title="Privacy Policy ">Privacy*/}
                                {/*        Policy</a>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form">
                    <div>
                        <div>
                            <WebhookForm/>
                            <br/>
                            <section>
                                <a href="https://integrately.com?ref=g7bsko&utm_campaign=Website Referral" target="_blank"><img src="https://integrately-images.s3-us-west-2.amazonaws.com/we-automate-with-integrately-light.svg" width="120" alt="Integrately - Integration platform" title="Click to learn more"/></a>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
    </Layout>
    );
};

export default Home;
